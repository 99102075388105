<template>
  <b-container fluid="xs">
    <vue-element-loading :active="isloading" spinner="ring" background-color="rgba(255,255,255,0.6)" size="400" color="#00F"/>
    <div v-show="items.length">
    <b-table-simple hover small caption-top responsive="xs">
      <b-thead head-variant="light">
        <b-tr>
          <b-th>{{$t('message.admbill_userid')}}</b-th>
          <b-th>{{$t('message.admbill_nickname')}}</b-th>
          <b-th>{{$t('message.admbill_paydate')}}</b-th>
          <b-th @click="extmflag">{{tmflag?$t('message.admbill_EndTime'):$t('message.admbill_StartTime')}}</b-th>
          <b-th>{{$t('message.admbill_billmoney')}}</b-th>
          <b-th>{{$t('message.admbill_reallmoney')}}</b-th>
          <b-th v-b-tooltip.hover title="0left/1right">{{$t('message.admbill_charger_port')}}</b-th>
          <b-th v-b-tooltip.hover title="power energy">{{$t('message.admbill_energy')}}</b-th>
          <b-th>{{$t('message.admbill_energyuse')}}</b-th>
          <b-th>{{$t('message.admbill_power')}}</b-th>
          <b-th v-b-tooltip.hover title="ChgOVER(0.10),ChgFinish(0.04~0.08)">{{$t('message.admbill_reason')}}</b-th>
        </b-tr>        
      </b-thead>
      <b-tbody>
        <b-tr v-for="(item, index) in items" :key="index">
          <b-td>{{item.uid}}</b-td>
          <b-td>{{item.nickname}}</b-td>
          <b-td v-b-tooltip.hover :title="item.paytime">{{item.paydate}}</b-td>
          <b-td v-b-tooltip.hover :title="item.startimeday">{{tmflag?item.paytime:item.startimestr}}</b-td>
          <b-td>{{item.billmoney}}</b-td>
          <b-td>{{item.paymoney}}</b-td>
          <b-td>{{item.chargerid}}#{{item.portid}}</b-td>
          <b-td>{{item.powend}}</b-td>
          <b-td>{{item.powused}}</b-td>
          <b-td>{{item.avgpow}}</b-td>
          <b-td>{{reasons[item.reason]}}</b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    </div>
    <div v-show="items.length==0" class="pl-5 pr-5">
      <b-card class="xnshadow" header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h4 class="mb-0"><b-icon icon="lightning"></b-icon> evcar AC charger history</h4>
        </template>
        <b-card-text>
          <p>
            no evcar AC charger history<br/><br/>
           </p>
        </b-card-text>
      </b-card>
    </div>
    <b-form inline class="mt-4 ml-4" @submit.stop.prevent>
      <b-button-group class="mr-4 mb-4">
        <b-button variant="success" @click="fetchprev" :disabled="pageid==0">{{$t('message.btn_prevpage')}}</b-button>
        <b-button v-if="pageid>0" variant="outline-success" :disabled="true" >{{pageid+1}}</b-button>
        <b-button variant="success" @click="fetchNext" :disabled="pageid==newpagestart.length">{{$t('message.btn_nextpage')}}</b-button>
      </b-button-group>
    <b-input-group class="mr-4 mb-4">
        <b-form-input placeholder="nickname" v-model="searchnickname" @keyup.enter="search"></b-form-input>
        <b-input-group-append>
          <b-button variant="info" @click="search">{{$t('message.btn_search')}}</b-button>
        </b-input-group-append>
    </b-input-group>
    </b-form>
    <b-modal v-model="modalshow" no-close-on-backdrop no-close-on-esc hide-header ok-only :ok-title="$t('message.btn_ok')"><span v-html="modalmsg"></span></b-modal>
  </b-container>
</template>

<script>
  export default {
    name: 'chargeac',
    mounted() {
      this.fetchData();
    },
    data() {
      return {
        isloading: false,
        modalshow:false,
        modalmsg: '',
        searchnickname:'',
        reasons: [
          this.$t('message.evResons0'),
          this.$t('message.evResons1'),
          this.$t('message.evResons2'),
          this.$t('message.evResons3'),
          this.$t('message.evResons4'),
          this.$t('message.evResons5'),
          this.$t('message.evResons6'),
          this.$t('message.evResons7'),
          this.$t('message.evResons8'),
          this.$t('message.evResons9'),
          this.$t('message.evResonsa')],
        items: [],
        pageid:0,
        tmflag:0,
        clicked:false,
        newpagestart:[]
      };
    },
    methods: {
      search() {
        this.pageid = 0;
        this.newpagestart = [];
        this.fetchData();
      },
      extmflag() {
        this.tmflag = 1-this.tmflag;
      },
      fetchprev() {
        this.pageid = this.pageid-1;
        this.fetchData();
      },
      fetchPage(page) {
        this.pageid = page;
        this.fetchData();
      },
      fetchNext() {
        this.pageid = this.pageid+1;
        this.fetchData();
      },
      async fetchData() {
        this.isloading = true;
        let w = window.innerWidth;
        let h = window.innerHeight;
        let lotoken = localStorage.getItem('token');
        let theapi = 'cbk=1&token='+lotoken+'&pagesize='+this.$pagesize+'&w='+w+'&h='+h;
        if (this.searchnickname) {
          theapi += '&search='+this.searchnickname;
        } else if (this.$route.query.uid) {
          theapi += '&uid='+this.$route.query.uid;
        } else if (this.$route.query.chargerid) {
          theapi += '&chargerid='+this.$route.query.chargerid;
        }
        if (this.pageid>0 && this.newpagestart[this.pageid-1]) {
          theapi = theapi + '&last='+encodeURIComponent(JSON.stringify(this.newpagestart[this.pageid-1]));
        }
        let axresp = await this.axios.post('/admbill?tm='+new Date().getTime(), theapi);
        this.isloading = false;
        if (axresp.status==200) {
          let axdata = axresp.data;
          this.items = axdata.Items;
          if (axdata.LastEvaluatedKey) {
            if (this.newpagestart.length<=this.pageid) {
              this.newpagestart.push(axdata.LastEvaluatedKey);
            } else {
              this.newpagestart[this.pageid] = axdata.LastEvaluatedKey;
            }
          }
        } else {
          this.items = [];
        }
      },
    }
  }
</script>
